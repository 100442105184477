<template>
  <div class="detail">
    <!-- <div class="topBar">
      <TopBarAdBlock v-if="mode == 1" />
      <TopBarDownloadBlock v-if="mode == 2" :onClick="handleClickDownload" />
    </div> -->
    <HeaderBar :hasArow="true" :clickLift="goBack" />

    <div class="video-block">
      <VideoPlayer ref="videoPlayerRef" :autoplay="autoplay" :muted="muted" :src="videoInfo?.videoSrc"
        :poster="videoInfo?.tvImage" width="100%" @videoPlayEnd="handleVideoPlayEnd" />

      <DownloadBlock v-if="showDownBlock" :onClick="handleClickDownload" />
      <AdBlock v-if="showAdBlock" :onClose="handleCloseAd" />
    </div>

    <div class="detail-content">
      <div class="detail-content-title">
        {{ videoInfo?.tvName }}
      </div>
      <div class="detail-content-subtitle">Episode {{ videoInfo?.series }}</div>
      <template>
        <SelectVideoVue :startneedpay="startneedpay" :mode="mode" :videoInfo="videoInfo" :videoList="videoList"
          :pageNum="pageNum" :pageSize="pageSize" :total="total" :totalPage="totalPage" :tvid="tvid"
          @changeVideo="handleChangeVideo" @changeVideoPage="handleChangeVideoPage" />
      </template>
    </div>

    <div class="select-video-popup-wrap" v-if="false">
      <div class="select-video-popup" @click="showSelectVideoPopup()">
        <div class="select-video-popup-left">
          <img src="../assets/selectVideoBtn.png" />
        </div>
        <div class="select-video-popup-content">{{ this.total }} Episode in total</div>
        <div class="select-video-popup-right">
          <img src="../assets/arrow.png" />
        </div>
        <PopupSelect v-if="showVideoList" :title="videoInfo?.tvName" @popupUse="closeSelectVideoPopup">
          <template>
            <SelectVideoVue :startneedpay="startneedpay" :mode="mode" :videoInfo="videoInfo" :videoList="videoList"
              :pageNum="pageNum" :pageSize="pageSize" :total="total" :totalPage="totalPage" :tvid="tvid"
              @changeVideo="handleChangeVideo" @changeVideoPage="handleChangeVideoPage" />
          </template>
        </PopupSelect>

      </div>
    </div>

  </div>
</template>
<script>

import VideoPlayer from "@/components/VideoPlayer";
// import TopBarAdBlock from "@/components/TopBarAdBlock";
// import TopBarDownloadBlock from "@/components/TopBarDownloadBlock";
import HeaderBar from "@/components/HeaderBar";
import PopupSelect from "@/components/PopupSelect";
import DownloadBlock from "@/components/DownloadBlock";
import AdBlock from "@/components/AdBlock";
import SelectVideoVue from "@/components/SelectVideo";

import api from '../service/index'
import utils from '@/utils/tools'
// 播放页：
// 投放操作：1.先后台生成推广链接。2。复制带参的链接。3.投放到广告页面。
// 参数：剧ID、第几集、模式：1广告模式 2.引导APP下载。

// 1.广告模式：顶部引导下载APP的banner不展示。广告模式根据后台设置的每X集弹一次广告。
//如后台设置3。则每看3集谈一次广告。广告模式没有解锁。所有的免费看。看的过程中插广告。

// 2.引导APP下载：默认从1第一集开始，自动往下轮动刷第2集。。。直到需要解锁的剧集。
// 开始展示下载APP的引导。只要有解锁全部是引导下载APP。在取播放链接时只需要取到免费集的播放链接即可。不用全部取出来。

// let startneedpay = 1 // 第几集开始上锁 展示下载block
export default {
  name: "DetailPage",
  components: {
    VideoPlayer,
    // TopBarDownloadBlock,
    // TopBarAdBlock,
    HeaderBar,
    PopupSelect,
    SelectVideoVue,
    DownloadBlock,
    AdBlock
  },
  data() {
    return {
      mode: '1', // ：1广告模式 2.引导APP下载。 默认1    
      m: 1000, // 几集弹一次广告 默认1      
      startneedpay: 1, // 第几集开始上锁 展示下载block
      sid: 0, // 播放第几集 默认1
      tvid: "",
      muted: false,
      autoplay: true,
      pageNum: 1,
      pageSize: 30,
      total: 0,
      totalPage: 0,
      videoInfo: {},
      videoList: {},

      showVideoList: false,
      showDownBlock: false,
      showAdBlock: false,

    };
  },
  computed: {},
  mounted() {
    this.tvid = this.$route.query?.tvid;
    this.sid = this.$route.query?.sid || 1;
    this.mode = this.$route.query?.mode ? Number(this.$route.query.mode) : 1;
    this.m = this.$route.query?.m ? Number(this.$route.query?.m) : 1000;

    if (this.sid > this.pageSize) {
      this.pageNum = Math.ceil(this.sid / this.pageSize)
    }
    this.initData();
  },
  methods: {
    initData() {
      const promises = [this.getDramaInfo(), this.getH5listPage()]
      Promise.all(promises).then(values => {
        this.startneedpay = values[0]?.data?.startNeedpay || 1;
        if (values[1]?.data?.list && values[1]?.data?.list?.length > 0) {
          if (this.sid !== 1) {
            this.videoInfo = values[1].data.list.find((item) => {
              return item.series == this.sid
            })
          } else {
            this.videoInfo = values[1].data.list[0]; // 默认选择第一集
          }
          this.h5PvLog();
          this.h5EventLogChanel('play');
          this.handlePlayMode(this.videoInfo)
          this.handleSetPlayList(values[1]);
        }
      }).catch(error => {
        console.log(error);
      })
    },
    getH5listPage() {
      return api.getH5listPage({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        tvid: this.tvid,
      })
    },
    getDramaInfo() {
      return api.getDramaInfo({
        tvid: this.tvid,
      });
    },
    h5EventLogChanel(eventname, cb) {
      api.h5EventLogChanel({
        eventname,
        sid: this.videoInfo.series,
        tvid: this.videoInfo.tvId,
        urlstring: this.$route.fullPath,
        ttclid: this.$route.query?.ttclid || undefined,
        middlemanId: this.$route?.query?.middlemanId || undefined,
        inviteCode: this.$route?.query?.inviteCode || undefined,
      }).then(() => {
        if (cb) {
          cb()
        }
      })
    },
    // pv
    h5PvLog() {
      api.h5PvLog({
        tvid: this.videoInfo.tvId,
        sid: this.videoInfo.series,
        mode: this.mode,
        middlemanId: this.$route.query?.middlemanId || undefined,
        inviteCode: this.$route.query?.inviteCode || undefined
      }).then((res) => {
        console.log(res);
      })
    },
    handleChangeVideo(item) {
      this.videoInfo = item;
      this.tvid = item?.tvId;
      this.sid = item?.series;
      this.showVideoList = false;
      this.h5PvLog();
      this.handlePlayMode(item)
    },
    handleChangeVideoPage(pageNum) {
      this.pageNum = pageNum;
      this.getH5listPage().then(res => {
        this.handleSetPlayList(res);
      });
    },
    handleSetPlayList(res) {
      if (res?.data?.list && res?.data?.list?.length > 0) {
        this.videoList = Object.assign({}, this.videoList, { [this.pageNum]: res.data.list });
        this.total = res.data.total;
        this.totalPage = res.data.totalPage;
      }
    },
    handlePlayMode(item) {
      if (this.mode == 1) {
        const seriesPlayAd = item?.series % this?.m;
        console.log(item?.series , this?.m, seriesPlayAd);
        if (this?.m == 1 || seriesPlayAd == 0) {
          console.log('广告');
          this.showAdBlock = true;
          this.muted = true;
          this.autoplay = false;
          this.$refs.videoPlayerRef.onPause();
        } else {
          console.log('播放');
          this.showAdBlock = false;
          this.muted = false;
          this.autoplay = false;
          this.$refs.videoPlayerRef.onPlay();
        }
      } else if (this.mode == 2) {
        if (item?.series >= this.startneedpay) { // 带锁的统一展示下载页
          this.showDownBlock = true;
          this.muted = true;
          this.autoplay = false;
          this.$refs.videoPlayerRef.onPause();
        } else {
          this.showDownBlock = false;
          this.muted = false;
          this.autoplay = false;
          this.$refs.videoPlayerRef.onPlay();
        }
      }
    },
    showSelectVideoPopup() {
      this.showVideoList = true;
    },
    closeSelectVideoPopup(e) {
      e?.stopPropagation();
      this.showVideoList = false;
    },
    handleClickDownload() {
      this.h5EventLogChanel('download', () => {
        utils.downloadApp();
      });
    },
    handleCloseAd() {
      this.showAdBlock = false;
      this.muted = false;
      this.autoplay = false;
      this.$refs.videoPlayerRef.onPlay();
    },
    goBack() {
      this.$router.replace(`/?mode=${this.mode}${this.$route.query?.middlemanId ? '&middlemanId=' + this.$route.query?.middlemanId : ''}${this.$route.query?.inviteCode ? '&inviteCode=' + this.$route.query?.inviteCode : ''}`);
    },
    handleVideoPlayEnd() {
      const playingVideoList = this.videoList[this.pageNum];
      const nextVideoIndex = playingVideoList.findIndex((item) => {
        if (this.videoInfo.series == item.series) {
          return item
        }
      })
      const nextVideo = playingVideoList[nextVideoIndex + 1];
      this.handleChangeVideo(nextVideo)
    }
  },
};
</script>
<style lang="less" scoped>
.detail {
  .topBar {
    position: relative;
  }
}

.detail-content {
  font-family: PingFang SC;
  padding: 12px;
  text-align: left;

  &-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: #333333;
  }

  &-subtitle {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0em;
    margin-top: 6px;
    color: #333333;
  }
}

.video-block {
  position: relative;
}

.select-video-popup-wrap {
  padding: 12px;
}

.select-video-popup {
  width: 100%;
  border-radius: 8px;
  opacity: 1;
  background: #2c2d2f;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  &-left {
    img {
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }

  &-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0em;
    color: #ffffff;
  }

  &-right {
    img {
      display: inline-block;
      width: 16px;
      height: 16px;
      transform: rotate(-90deg);
    }
  }
}
</style>